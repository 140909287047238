<template>
  <div>
    <h3>Generative AI</h3>
    <b-card
      no-body
    >
      <p>
        Configuration of settings related to Generative AI actions
      </p>
    </b-card>
    <hr>
    <b-form-group
      label="Company/brand name"
    >
      <b-form-input
        id="companyName"
        v-model="companyName"
        type="text"
        :state="$v.companyName.$invalid ? false : null"
        aria-describedby="companyNameFeedback"
        placeholder="Company name"
      />
      <b-form-invalid-feedback
        id="companyNameFeedback"
      >
        <div v-if="!$v.companyName.required">
          You must specify a company/brand name when using Generative AI.
        </div>
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group
      label="Maximum number of historic user messages to use (0 = unlimited)"
    >
      <b-form-input
        id="historyCount"
        v-model="historyCount"
        type="number"
        min="0"
        number
        :state="$v.historyCount.$invalid ? false : null"
        aria-describedby="historyCountFeedback"
        placeholder="Max history count"
      />
      <b-form-invalid-feedback
        id="historyCountFeedback"
      >
        <div v-if="!$v.historyCount.required">
          You must specify a max history count. Use 0 for unlimited.
        </div>
        <div v-if="!$v.historyCount.integer">
          Only integers are allowed
        </div>
        <div v-if="!$v.historyCount.nonNegative">
          Value cannot be negative
        </div>
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group label="Bot audience">
      <b-form-radio-group
        v-model="userFaced"
        :options="userFacedOptions"
      />
    </b-form-group>
    <b-form-group label="GPT persona" class="w-100">
      <b-dropdown
        menu-class="bg-white p-0"
        toggle-class="bg-white text-dark"
      >
        <template #button-content>
          <span v-if="getSelectedPersona">
            <font-awesome-icon class="mr-1" :icon="getSelectedPersona.icon" />
            {{ getSelectedPersona.text }}
          </span>
          <span v-else>Select persona</span>
        </template>
        <b-dropdown-item
          v-for="(persona, index) in gptPersonas"
          :key="index"
          @click="gptPersona = persona.value"
        >
          <b-row style="min-width:500px;">
            <b-col class="my-auto" cols="auto" style="width:60px;">
              <font-awesome-icon class="h2 my-auto" :icon="persona.icon" />
            </b-col>
            <b-col cols="10" class="">
              <h5 class="font-weight-bold my-1">
                {{ persona.text }}
              </h5>
              <p style="white-space:normal;">
                {{ persona.description }}
              </p>
            </b-col>
          </b-row>
        </b-dropdown-item>
      </b-dropdown>
      <b-dropdown
        class="ml-2"
        text="Additional conditions"
        boundary="viewport"
        menu-class="bg-white p-0"
        toggle-class="bg-white text-dark"
      >
        <b-dropdown-form form-class="w-auto p-3">
          <b-form-group label="Negative sentiment">
            <b-input-group prepend="If a customer is angry, use" append="persona">
              <b-form-select
                :value="personaCondition('negative')"
                :options="additionalPersonaOptions"
                @input="v=>setCondition('negative', v)"
              />
            </b-input-group>
          </b-form-group>
          <b-form-group label="Neutral sentiment">
            <b-input-group prepend="If a customer is neutral, use" append="persona">
              <b-form-select
                :value="personaCondition('neutral')"
                :options="additionalPersonaOptions"
                @input="v=>setCondition('neutral', v)"
              />
            </b-input-group>
          </b-form-group>
          <b-form-group class="mb-0" label="Positive sentiment">
            <b-input-group prepend="If a customer is happy, use" append="persona">
              <b-form-select
                :value="personaCondition('positive')"
                :options="additionalPersonaOptions"
                @input="v=>setCondition('positive', v)"
              />
            </b-input-group>
          </b-form-group>
        </b-dropdown-form>
      </b-dropdown>
    </b-form-group>
    <b-form-group label="Allowed emojis">
      <b-form-tags
        v-model="selectedEmojis"
        add-on-change
        no-outer-focus
      >
        <template
          #default="{
            tags, inputAttrs, inputHandlers, disabled, removeTag,
          }"
        >
          <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
            <li v-for="tag in tags" :key="tag" class="list-inline-item">
              <b-form-tag
                :title="tag"
                :disabled="disabled"
                variant="secondary"
                @remove="removeTag(tag)"
              >
                {{ tag }}
              </b-form-tag>
            </li>
          </ul>
          <b-form-select
            v-bind="inputAttrs"
            :disabled="disabled || availableEmojiOptions.length === 0"
            :options="availableEmojiOptions"
            v-on="inputHandlers"
          >
            <template #first>
              <option disabled value="">
                Choose emoji...
              </option>
            </template>
          </b-form-select>
        </template>
      </b-form-tags>
    </b-form-group>
    <b-form-group label="Word replacement" class="mb-0" />
    <small class="mb-1 text-muted d-block">
      Specified words will be replaced from the longest to the shortest match.<br>
      You can specify which words should be case-sensitive
      by clicking the button on the right of each entry.<br>
      To remove a word, leave "Word used as replacement" field empty.
    </small>
    <WordReplacement
      fixed-input
      :replacements="getWordReplacements"
      @add="v=>addWordReplacement(v)"
      @update="v=>updateWordReplacement(v)"
      @remove="v=>removeWordReplacement(v)"
    />
    <b-form-group class="mt-2">
      <b-form-checkbox
        v-model="anonymizeTranscript"
        switch
        size="md"
      >
        Anonymize transcript using entity recognizers
      </b-form-checkbox>
      <small class="mb-1 text-muted d-block">
        If you enabled this, the whole transcript including both bot and user messages
        will be anonymized before it is sent to the generative AI model.<br>
        Additional context added to "Generate AI reply" activities will also be anonymized.<br>
        Anonymization is done using all entity recognizers configured on the <b-link :to="{ name: 'entities' }">entities page</b-link>
      </small>
    </b-form-group>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, integer, minValue } from 'vuelidate/lib/validators';
import { mapGetters, mapMutations } from 'vuex';
import { gptPersonas, emojiOptions } from '@/js/constants';
import WordReplacement from '@/components/WordReplacement.vue';

export default {
  name: 'BasicConfig',
  components: {
    WordReplacement,
  },
  mixins: [validationMixin],
  data() {
    return {
      gptPersonas,
      emojiOptions,
      userFacedOptions: [
        { text: 'Users (e.g. external chatbot)', value: true },
        { text: 'Agents (e.g. agent-assist chatbot)', value: false },
      ],
    };
  },
  computed: {
    ...mapGetters('botManipulation/activeBot/config/generativeAI', [
      'getCompanyName',
      'getHistoryCount',
      'getGptPersona',
      'getAllowedEmojis',
      'getPersonaConditions',
      'getUserFaced',
      'getWordReplacements',
      'getAnonymizeTranscript',
    ]),
    companyName: {
      get() {
        return this.getCompanyName;
      },
      set(value) {
        this.setCompanyName({ name: value });
      },
    },
    historyCount: {
      get() {
        return this.getHistoryCount;
      },
      set(value) {
        this.setHistoryCount({ count: value });
      },
    },
    gptPersona: {
      get() {
        return this.getGptPersona;
      },
      set(value) {
        this.setGptPersona({ persona: value });
      },
    },
    selectedEmojis: {
      get() {
        return this.getAllowedEmojis;
      },
      set(value) {
        this.setAllowedEmojis({ emojis: value });
      },
    },
    userFaced: {
      get() {
        return this.getUserFaced === undefined ? true : this.getUserFaced;
      },
      set(value) {
        this.setUserFaced({ value });
      },
    },
    anonymizeTranscript: {
      get() {
        return !!this.getAnonymizeTranscript;
      },
      set(value) {
        this.setAnonymizeTranscript({ value });
      },
    },
    getSelectedPersona() {
      return this.gptPersonas.find((e) => e.value === this.gptPersona);
    },
    availableEmojiOptions() {
      return this.emojiOptions.filter((opt) => this.selectedEmojis.indexOf(opt) === -1);
    },
    additionalPersonaOptions() {
      return [{ text: 'Default', value: null }]
        .concat(this.gptPersonas.map((e) => ({ text: e.text, value: e.value })));
    },
  },
  methods: {
    ...mapMutations('botManipulation/activeBot/config/generativeAI', [
      'setCompanyName',
      'setHistoryCount',
      'setGptPersona',
      'setAllowedEmojis',
      'setPersonaCondition',
      'setUserFaced',
      'addWordReplacement',
      'updateWordReplacement',
      'removeWordReplacement',
      'setAnonymizeTranscript',
    ]),
    personaCondition(sentiment) {
      return this.getPersonaConditions[sentiment];
    },
    setCondition(sentiment, value) {
      this.setPersonaCondition({ sentiment, value });
    },
  },
  validations: {
    companyName: {
      required,
    },
    historyCount: {
      required,
      integer,
      nonNegative: minValue(0),
    },
  },
};
</script>
