<template>
  <b-card
    v-if="activeModel"
    class="r-75"
    body-class="p-3"
    title="Configuration"
  >
    <b-form
      @submit.prevent
      @input="$v.localData.$touch()"
    >
      <b-form-group
        label="Name"
      >
        <b-form-input
          v-model="localData.name"
          :state="$v.localData.name.$invalid ? false : null"
          :disabled="isNodeClassifier"
        />
        <b-form-invalid-feedback>
          <div v-if="!$v.localData.name.$required">
            The classifier must have a name
          </div>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        label="Description"
      >
        <b-form-input
          v-model="localData.description"
          :disabled="isNodeClassifier"
        />
      </b-form-group>
      <b-form-group
        label="Type"
      >
        <b-form-input
          v-model="localData.typeText"
          disabled
        />
      </b-form-group>
      <b-form-group
        label="Language"
      >
        <b-form-select
          :value="localData.id"
          :options="variantOptions"
          @change="(x) => $router.push({ name: 'nlu-model-page', params: { modelId: x } })"
        />
      </b-form-group>
      <template v-if="!isSWML">
        <b-form-group
          label="Language model"
        >
          <b-form-select
            v-model="localData.config.language_model"
            :options="languageModelOptions"
          />
          <template
            v-if="languageModelOptions.length === 1"
          >
            No language models have been uploaded to NLU module.<br>
            You cannot start training of a NLU-model without having selected a language model.<br>
            You can upload a language model from the
            <b-link
              :to="{ name: 'nlu' }"
            >
              NLU frontpage
            </b-link>
          </template>
        </b-form-group>
        <b-form-group
          label="Related bot"
          description="Select the bot that will be the primary use case for the classifier. The
            training pipeline will then use the entity extractors of that bot."
        >
          <b-form-select
            v-model="localData.config.bot"
            :options="botOptions"
          />
        </b-form-group>
        <b-form-group
          label="Max age of manually trained labels"
          description="Default behaviour is to include all manually trained labels.
                 Here you can overwrite this behaviour with a specific age limit."
        >
          <b-input-group>
            <b-input-group-prepend>
              <b-dropdown
                :text="manualLabelsValue.text"
                variant="primary"
              >
                <b-dropdown-item
                  v-for="item, index in manualLabelsOptions"
                  :key="item.value"
                  :active="index === manualLabelsIndex"
                  @click="toggleManualLabels(index)"
                >
                  {{ item.text }}
                </b-dropdown-item>
              </b-dropdown>
            </b-input-group-prepend>

            <b-form-datepicker
              v-if="manualLabelsValue.value === 'date'"
              v-model="localData.config.manual_labels_start"
            />
            <b-form-input
              v-else
              v-model.number="localData.config.manual_labels_age_in_days"
              type="number"
              min="0"
              placeholder="No maximum age in days specified"
            />

            <b-input-group-append
              v-if="localData.config.manual_labels_start
                || localData.config.manual_labels_age_in_days"
            >
              <b-button
                @click="removeManualLabelsAgeRestrictions"
              >
                <font-awesome-icon icon="times" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-form-group
          label="Max age of autolabels"
          description="Default behavior is to include the last 50,000 autolabels. Here
                you can overwrite this behavior with a specific age limit. Note: autolabels are
                user queries automatically labeled based on user feedback."
        >
          <b-input-group>
            <b-input-group-prepend>
              <b-dropdown
                :text="autolabelsValue.text"
                variant="primary"
              >
                <b-dropdown-item
                  v-for="item, index in autolabelsOptions"
                  :key="item.value"
                  :active="index === autolabelsIndex"
                  @click="toggleAutolabels(index)"
                >
                  {{ item.text }}
                </b-dropdown-item>
              </b-dropdown>
            </b-input-group-prepend>

            <b-form-datepicker
              v-if="autolabelsValue.value === 'date'"
              v-model="localData.config.autolabels_start"
            />
            <b-form-input
              v-else
              v-model.number="localData.config.autolabels_age_in_days"
              type="number"
              min="0"
              placeholder="No maximum age in days specified"
            />

            <b-input-group-append
              v-if="localData.config.autolabels_start || localData.config.autolabels_age_in_days"
            >
              <b-button
                @click="removeAutolabelsAgeRestrictions"
              >
                <font-awesome-icon icon="times" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-form-group
          label="Automatic training"
          description="If the data has increased by 10%, automatic training will be conducted
           weekly. If not, it will be conducted approximately once per month on a random basis."
        >
          <b-form-checkbox
            v-model="localData.autoTrain"
            switch
          />
        </b-form-group>
      </template>
    </b-form>

    <b-button-group>
      <b-button
        variant="primary"
        @click="localUpdateClassifier"
      >
        Update configuration
        <b-spinner
          v-if="isUpdating"
          small
        />
      </b-button>
      <b-button
        v-b-modal.new-classifier-variant-modal
        variant="info"
      >
        New language
      </b-button>
      <b-button
        variant="danger"
        @click="promptDeleteClassifier"
      >
        {{ activeModel.mainClassifier ? "Delete variant" : "Delete classifier" }}
      </b-button>
      <b-button
        v-if="activeModel.mainClassifier === null"
        variant="warning"
        @click="promptArchiveClassifier"
      >
        Archive classifier
      </b-button>
      <b-button
        v-if="activeModel.nodeId"
        :to="editNodeLink(activeModel.nodeId)"
      >
        Go to node
      </b-button>
    </b-button-group>
    <b-modal
      id="new-classifier-variant-modal"
      title="New classifier variant"
      :ok-disabled="$v.newVariantLanguage.$invalid"
      @ok="createNewVariant"
    >
      <b-form-group
        label="Language"
      >
        <b-form-select
          v-model="newVariantLanguage"
          :options="LanguageOptions"
          :state="$v.newVariantLanguage.$invalid ? false : null"
        />
        <b-form-invalid-feedback>
          <div v-if="!$v.newVariantLanguage.required">
            Your must choose a language.
          </div>
        </b-form-invalid-feedback>
      </b-form-group>
    </b-modal>
  </b-card>
</template>

<script>

import axios from 'axios';
import { mapGetters, mapActions, mapState } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { LanguageOptions } from '@/js/constants';
import endpoints from '@/js/urls';

const _ = require('lodash');

export default {
  name: 'ModelConfig',
  mixins: [validationMixin],
  data() {
    return {
      localData: {},
      version: null,
      isUpdating: false,
      newVariantLanguage: null,
      LanguageOptions,
      autolabelsOptions: [
        { text: 'Age in days', value: 'days' },
        { text: 'Start date', value: 'date' },
      ],
      manualLabelsOptions: [
        { text: 'Age in days', value: 'days' },
        { text: 'Start date', value: 'date' },
      ],
      autolabelsIndex: 0,
      manualLabelsIndex: 0,
    };
  },
  computed: {
    ...mapState('nlu/languageModel', [
      'languageModels',
      'languageModelIds',
    ]),
    ...mapGetters('nlu/classifier', [
      'getModelFromId',
      'labels',
      'activeModel',
      'modelVariants',
    ]),
    ...mapGetters('botManipulation', [
      'botsNotHidden',
    ]),
    autolabelsValue() {
      if (this.autolabelsIndex === null) {
        return null;
      }
      return this.autolabelsOptions[this.autolabelsIndex];
    },
    manualLabelsValue() {
      if (this.manualLabelsIndex === null) {
        return null;
      }
      return this.manualLabelsOptions[this.manualLabelsIndex];
    },
    isNodeClassifier() {
      return this.activeModel.nodeId !== null;
    },
    isSWML() {
      return this.activeModel.type === 'swml';
    },
    languageModelOptions() {
      const options = [{ text: 'Select a language model', value: null }];
      for (const id of this.languageModelIds) {
        const languageModel = this.languageModels[id];
        const text = `${languageModel.display_type} - ${languageModel.description}`;
        options.push({ text, value: id });
      }
      return options;
    },
    selectedLanguageModel() {
      const languageModelId = this.localData.config.language_model;
      if (languageModelId) {
        return this.languageModels[languageModelId];
      }
      return null;
    },
    modelType() {
      if (this.selectedLanguageModel) {
        return this.selectedLanguageModel.type;
      }
      return null;
    },
    botOptions() {
      const botOptions = this.botsNotHidden.map((bot) => ({ value: bot.id, text: bot.name }));
      botOptions.unshift({ value: null, text: 'No bot chosen' });
      return botOptions;
    },
    variantOptions() {
      return this.modelVariants(this.activeModel.id).map(
        (x) => ({ value: x.id, text: x.language + (x.mainClassifier ? '' : ' (main)') }),
      );
    },
  },
  watch: {
    localData: {
      handler(newValue) {
        const autolabelAgeInDays = newValue?.config?.autolabels_age_in_days;
        this.autolabelsIndex = autolabelAgeInDays !== null && autolabelAgeInDays !== '' ? 0 : 1;
        const manualLabelAgeInDays = newValue?.config?.manual_labels_age_in_days;
        this.manualLabelsIndex = manualLabelAgeInDays !== null && manualLabelAgeInDays !== '' ? 0 : 1;
      },
      immediate: true,
    },
  },
  created() {
    this.localData = _.cloneDeep(this.activeModel);
    delete this.localData.type;
  },
  methods: {
    ...mapActions('nlu/classifier', [
      'updateClassifier',
      'fetchGlobalNLUModels',
      'deleteClassifier',
      'archiveClassifier',
    ]),
    toggleAutolabels(index) {
      this.autolabelsIndex = index;
    },
    toggleManualLabels(index) {
      this.manualLabelsIndex = index;
    },
    async localUpdateClassifier() {
      this.isUpdating = true;
      const config = _.cloneDeep(this.localData.config);
      if (this.autolabelsValue.value === 'days') {
        config.autolabels_start = null;
      }
      if (this.autolabelsValue.value === 'date') {
        config.autolabels_age_in_days = null;
      }
      if (config.autolabels_age_in_days === '') {
        config.autolabels_age_in_days = null;
      }
      if (this.manualLabelsValue.value === 'days') {
        config.manual_labels_start = null;
      }
      if (this.manualLabelsValue.value === 'date') {
        config.manual_labels_age_in_days = null;
      }
      if (config.manual_labels_age_in_days === '') {
        config.manual_labels_age_in_days = null;
      }
      const classifierId = this.$route.params.modelId;
      await this.updateClassifier({
        classifierId,
        name: this.localData.name,
        description: this.localData.description,
        config,
        autoTrain: this.localData.autoTrain,
      });
      await this.fetchGlobalNLUModels();
      this.isUpdating = false;
    },
    async createNewVariant() {
      const data = { clfId: this.activeModel.id, language: this.newVariantLanguage };
      const resp = await axios.post(endpoints.newClassifierVariant, data, {
        headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` },
      });
      await this.fetchGlobalNLUModels();
      this.$router.push({ name: 'nlu-model-page', params: { modelId: resp.data.variant_id } });
    },
    async promptDeleteClassifier() {
      let deleteString;
      if (this.activeModel.mainClassifier) {
        deleteString = `language variant: ${this.activeModel.language}`;
      } else {
        deleteString = `classifier: ${this.activeModel.name}`;
      }
      const question = `Are you sure you want to delete the ${deleteString}?`;
      if (await this.$bvModal.msgBoxConfirm(question)) {
        this.deleteClassifier({ classifierId: this.activeModel.id }).then((response) => {
          if (response.success) {
            this.fetchGlobalNLUModels();
          } else {
            this.$bvModal.msgBoxOk(
              `Failed to delete model. Most likely because it is being used by a current bot or
              staged bot.`,
            );
          }
        });
        if (this.activeModel.mainClassifier) {
          const modelId = this.activeModel.mainClassifier;
          this.$router.push({ name: 'nlu-model-page', params: { modelId } });
        } else {
          this.$router.push({ name: 'nlu' });
        }
      }
    },
    async promptArchiveClassifier() {
      const question = `Are you sure you want to archive the classifier: ${this.activeModel.name}?`;
      if (await this.$bvModal.msgBoxConfirm(question)) {
        this.archiveClassifier({ classifierId: this.activeModel.id }).then((response) => {
          if (response.success) {
            this.fetchGlobalNLUModels();
          } else {
            this.$bvModal.msgBoxOk(
              'Failed to archive model. Most likely because it is being used by a current bot.',
            );
          }
        });
        this.$router.push({ name: 'nlu' });
      }
    },
    removeAutolabelsAgeRestrictions() {
      this.localData.config.autolabels_start = null;
      this.localData.config.autolabels_age_in_days = null;
    },
    removeManualLabelsAgeRestrictions() {
      this.localData.config.manual_labels_start = null;
      this.localData.config.manual_labels_age_in_days = null;
    },
  },
  validations: {
    localData: {
      name: {
        required,
      },
    },
    newVariantLanguage: { required },
  },
};
</script>
<style scoped>
::v-deep .input-group-append .btn *:not(.btn) {
  color: var(--white);
}
::v-deep .dropdown-item.active {
  background-color: #004360 !important;
}
::v-deep .dropdown-menu {
  box-shadow: 0 !important;
}
</style>
