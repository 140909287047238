import Quill from 'quill';

// Raise below warning when smart-node is set to use a custom node, but none is speficied
export const NO_CUSTOMFALLBACK_SPECIFIED_WARNING = 'NO_CUSTOMFALLBACK_SPECIFIED_WARNING';
export const AUTH_SETTING_CONFLICT_WARNING = 'AUTH_SETTING_CONFLICT_WARNING';
export const AUTH_MISSING_FALLBACK = 'AUTH_MISSING_FALLBACK';
export const NODE_SAME_NAME = 'NODE_SAME_NAME';
export const MAKE_BOT_FAILURE = 'MAKE_BOT_FAILURE';
export const SYNTAX_ERROR = 'SYNTAX_ERROR';
export const CONTENT_ERROR = 'CONTENT_ERROR';
export const ACTIONS_HAVE_WARNINGS = 'ACTIONS_HAVE_WARNINGS';
export const YESNO_MODEL_WARNING = 'YESNO_MODEL_WARNING';
export const UNSUPPORTED_CHAT_ACTION = 'UNSUPPORTED_CHAT_ACTION';
export const COMPOUND_RESPONSE_ERROR = 'COMPOUND_RESPONSE_ERROR';
// INTERNAL_ are for problems that cannot be solved by user
export const INTERNAL_BAD_ACTIVITY_STATE = 'INTERNAL_BAD_ACTIVITY_STATE';

/*
Use TaskStatus in places where you would otherwise have to type, remember and match the literal
string sent from bakcend.
*/
export const TaskStatus = Object.freeze({
  PENDING: 'PENDING', // Matches status sent from backend and signifies a task is PENDING or ONGOING
  TASK_COMPLETED: 'SUCCESS', // Matches status sent from backend and signifies a task has completed
  // Used for internal reporting in frontend, it does not match a value sent from backend
  TASK_FAILED: 'TASK_FAILED',
  // Used for internal reporting in frontend, does not match a value sent from backend
  UNKNOWN_STATUS: 'UNKNOWN',
});

export const BotUploadStatus = Object.freeze({
  IN_PROGRESS: 'IN_PROGRESS',
  FAILED: 'FAILED',
  UPLOAD_SUCCESFULL: 'UPLOAD_SUCCESFULL',
});

const basePlatforms = {
  ZENDESK: 'zendesk',
  ZENDESKSUPPORT: 'zendesk-support',
  ZENDESKSUNCO: 'zendesk-sunco',
  SUPCHAT: 'supchat',
  GENESYS: 'genesyscloud',
  PUZZEL: 'puzzel',
  PUZZELTICKET: 'puzzel-ticket',
  JSONWEBHOOK: 'jsonwebhook',
  BOMGAR: 'bomgar',
  BOMGARAGENT: 'bomgar-agent',
  TOPDESKINCIDENT: 'topdesk-incident',
  USERDEFINED: 'user-defined',
};

export const wysiwygPlatforms = [
  'zendesk-support', 'jsonwebhook', 'topdesk-incident', 'puzzel-ticket',
];

// If you add new voicebots also add them in botadmin
export const voicePlatforms = {
  VONAGE: 'vonage',
  VONAGEPUZZEL: 'vonage-puzzel',
  VONAGETRIO: 'vonage-trio',
  AUDIOCODES: 'audiocodes',
};

// Webhook platforms where inactivity prompt does not make sense.
export const webhookPlatforms = [
  'puzzel', 'jsonwebhook',
];

export const platformDisplayNames = {
  ZENDESK: 'Zendesk Conversation',
  ZENDESKSUPPORT: 'Zendesk Support',
  ZENDESKSUNCO: 'Zendesk Sunshine Conversation',
  SUPCHAT: 'SupChat',
  GENESYS: 'Genesys Cloud',
  PUZZEL: 'Puzzel',
  PUZZELTICKET: 'Puzzel Case Management',
  BOMGAR: 'Bomgar',
  BOMGARAGENT: 'Bomgar (Agent)',
  TOPDESKINCIDENT: 'TOPdesk Incident',
  JSONWEBHOOK: 'Json WebHook',
  VONAGE: 'Vonage',
  VONAGEPUZZEL: 'Vonage (Puzzel)',
  VONAGETRIO: 'Vonage (Trio)',
  AUDIOCODES: 'AudioCodes',
  USERDEFINED: 'User-Defined',
};

export const platforms = { ...basePlatforms };

export const voicebotEngines = Object.freeze({
  stt: [
    { text: 'Google Speech-to-Text', value: 'google', disabled: true },
    { text: 'Azure Speech Recognizer', value: 'azure' },
    { text: 'Azure Speech Recognizer on Premise', value: 'azure-on-premise' },
  ],
  tts: [
    { text: 'Google Text-to-Speech', value: 'google', disabled: true },
    { text: 'Amazon Polly', value: 'amazon', disabled: true },
    { text: 'Azure Speech Synthesizer', value: 'azure' },
    { text: 'Azure Speech Synthesizer on Premise', value: 'azure-on-premise' },
  ],
});

export const chartColors = {
  // Inspired by https://colorhunt.co/palette/92752
  primaryColor: 'rgba(20, 58, 82, 1.0)',
  primaryColorTransparent: 'rgba(20, 58, 82, 0.8)',
  secondaryColor: 'rgba(110, 130, 138, 1.0)',
  secondaryColorTransparent: 'rgba(110, 130, 138, 0.8)',
  reddishColor: 'rgba(230, 115, 115, 1.0)',
  reddishColorTransparent: 'rgba(230, 115, 115, 0.8)',
  lightColor: 'rgba(252, 232, 211, 1.0)',
  lightColorTransparent: 'rgba(252, 232, 211, 0.8)',
  greenishBar: 'rgb(64, 180, 180)',
  lightBlue: 'rgb(205, 227, 235)',
  blue: 'rgb(40, 77, 102)',
  darkBlue: 'rgb(24, 48, 116)',
  deepDarkBlue: 'rgb(10,22,46)', // I could not distinguish this from black on a Samsung screen, but could on my Mac.
  lightGrey: 'rgb(180, 183, 191)',
};

export const DATASET_STATE_LOADED = 'DATASET_STATE_LOADED';
export const DATASET_STATE_LOADING = 'DATASET_STATE_LOADING';
export const DATASET_STATE_LOAD_FAILED = 'DATASET_STATE_LOAD_FAILED';
export const DATASET_STATE_UNINITIALIZED = 'DATASET_STATE_UNINITIALIZED';
export const DATASET_STATE_SYNC_FAILED = 'DATASET_STATE_SYNC_FAILED';

export const DATASET_DELETION_IN_PROGRESS = 'DATASET_DELETING';
export const DATASET_DELETED_SUCCESSFULLY = 'DATASET_DELETED';
export const DATASET_DELETION_ERROR = 'DATASET_DELETION_ERROR';

export const DATASOURCE_CREATE_IN_PROGRESS = 'DATASOURCE_CREATING';
export const DATASOURCE_CREATE_FAILED = 'DATASOURCE_CREATE_FAILED';
export const DATASOURCE_EDIT_IN_PROGRESS = 'DATASOURCE_EDITING';
export const DATASOURCE_EDIT_FAILED = 'DATASOURCE_EDIT_FAILED';

export const DATASOURCE_DELETION_IN_PROGRESS = 'DATASOURCE_DELETING';
export const DATASOURCE_DELETED_SUCCESSFULLY = 'DATASOURCE_DELETED';
export const DATASOURCE_DELETION_ERROR = 'DATASOURCE_DELETION_ERROR';

// These strings must match those returned by backend
export const userPermission = Object.freeze({
  TRANSCRIPT: 'transcript',
  OBSERVER: 'observer',
  LIMITED: 'limited',
  NORMAL: 'normal',
  SUPERUSER: 'super',
  NO_PERMISSIONS: 'no-permissions',
});

export const nodeTypes = Object.freeze({
  MULTIPLE_CHOICE: 'multipleChoice',
  SIMPLE: 'simple',
  SUBFLOW: 'subflow',
  SMALLTALK: 'smallTalk',
  QA: 'questionAnswer',
});

/**
 * Constants defined here match those sent by backend when errors arise when computing diagnostics.
 * These constants allows us to map backend codes to a frontend-description text of our choosing,
 * thereby decoupling frontend and backend somewhat :)
 * I expect more constants to be added as we support reporting more types of errors in frontend.
 */
export const BackendDiagnosticsErrors = Object.freeze({
  INVALID_CLASSIFIER: 'INVALID_CLASSIFIER',
});

/**
 * The ComputationTaskStatus describes the state of a computation that was submitted to backend.
 * The corresponding string values matches those used in backend.
 * This status does _not_ make any interpretation of the _result of_ the metric.
 * For instance say the computation of whether the bot's platform has been configured has succeeded,
 * then that computation's ComputationTaskStatus is SUCCEEDED. However, the SUCCEEDED value does not
 * describe whether the bot's platform actually has been configured or not.
 * To review that you must inspect the _result_ of the computation.
 */
export const ComputationTaskStatus = Object.freeze({
  IN_PROGRESS: 'IN_PROGRESS',
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED',
  QUEUED: 'QUEUED',
});

export const ROOT_TREE_NODE = 'root_tree_node';

export const NEUTRAL_NODE_ID = 'neutral';

export const NamedEntityRecognizerOptions = Object.freeze({
  DomainRecognizer: { displayText: 'Domain Recognizer' },
  EmailRecognizer: { displayText: 'Email Recognizer' },
  IPRecognizer: { displayText: 'IP Recognizer' },
  PhoneNumberRecognizer: { displayText: 'PhoneNumber Recognizer' },
  AddressRecognizer: { displayText: 'Address Recognizer' },
  CityRecognizer: { displayText: 'City Recognizer' },
  GirokortRecognizer: { displayText: 'Girokort Recognizer' },
  RoadRecognizer: { displayText: 'Road Recognizer' },
  RoadWithHouseNumberRecognizer: { displayText: 'Road Recognizer including street number' },
  ZipCodeRecognizer: { displayText: 'Zipcode Recognizer' },
  HouseNumberRecognizer: { displayText: 'House number Recognizer' },
  CPRNumberRecognizer: { displayText: 'CPR number Recognizer' },
  FullNameRecognizer: { displayText: 'Name Recognizer' },
  DateRecognizer: { displayText: 'Date recognizer' },
});

export const AnonymizerOptions = Object.freeze({
  DomainRecognizer: { displayText: 'Domain Recognizer' },
  EmailRecognizer: { displayText: 'Email Recognizer' },
  IPRecognizer: { displayText: 'IP Recognizer' },
  PhoneNumberRecognizer: { displayText: 'PhoneNumber Recognizer' },
  AddressRecognizer: { displayText: 'Address Recognizer' },
  GirokortRecognizer: { displayText: 'Girokort Recognizer' },
  CPRNumberRecognizer: { displayText: 'CPR number Recognizer' },
  FullNameRecognizer: { displayText: 'Name Recognizer' },
  DateRecognizer: { displayText: 'Date recognizer' },
});

// if you update this, make sure to also update language_names in change_bot_language.py
export const LanguageOptions = Object.freeze([
  { value: 'da', text: 'Danish' },
  { value: 'nl', text: 'Dutch' },
  { value: 'en', text: 'English' },
  { value: 'fi', text: 'Finnish' },
  { value: 'fr', text: 'French' },
  { value: 'de', text: 'German' },
  { value: 'it', text: 'Italian' },
  { value: 'no', text: 'Norwegian' },
  { value: 'ro', text: 'Romanian' },
  { value: 'es', text: 'Spanish' },
  { value: 'sv', text: 'Swedish' },
  { value: 'tr', text: 'Turkish' },
]);

export const compoundResponseTypes = Object.freeze([
  {
    name: 'Rich Message',
    id: 'UnrestrictedMessage',
    supports: ['image', 'title', 'message', 'link', 'buttons'],
    requires: [],
    supportedIn: [platforms.JSONWEBHOOK, platforms.ZENDESKSUNCO],
  },
  {
    name: 'Button Message',
    id: 'ZendeskButtons',
    supports: ['message', 'buttons'],
    requires: ['message', 'buttons'],
    supportedIn: [platforms.ZENDESK],
  },
  {
    name: 'Panel Message',
    id: 'ZendeskPanel',
    supports: ['image', 'title', 'message', 'link', 'buttons'],
    requires: ['title'],
    supportedIn: [platforms.ZENDESK],
  },
  // See CompoundResponseActivity.types in activities.py for description of what this represents
  {
    name: 'Id Message',
    id: 'PDOption',
    supports: ['title', 'message'],
    requires: ['title', 'message'],
    supportedIn: [platforms.JSONWEBHOOK],
  },
  {
    name: 'Generic Card',
    id: 'PuzzelGenericCard',
    supports: ['image', 'image-alt', 'title', 'message', 'link', 'link-text'],
    requires: ['image'],
    supportedIn: [platforms.PUZZEL],
  },
]);

export const anonymizationTypes = Object.freeze([
  { value: 'swml', text: 'Built-in recognizer' },
  { value: 'regex', text: 'Regular Expression' },
]);

// wysiwyg conf
const Break = Quill.import('blots/break');
const Embed = Quill.import('blots/embed');

class SmartBreak extends Break {
  // eslint-disable-next-line class-methods-use-this
  length() {
    return 1;
  }

  // eslint-disable-next-line class-methods-use-this
  value() {
    return '\n';
  }

  insertInto(parent, ref) {
    Embed.prototype.insertInto.call(this, parent, ref);
  }
}

SmartBreak.blotName = 'break';
SmartBreak.tagName = 'BR';
const fontSizeArr = ['8pt', '9pt', '10pt', '11pt', '12pt', '14pt', '16pt', '20pt', '24pt', '32pt', '42pt', '54pt', '68pt', '84pt', '98pt'];

const Size = Quill.import('attributors/style/size');
Size.whitelist = fontSizeArr;
Quill.register(Size, true);
Quill.register(SmartBreak);

const standardToolbar = [
  [],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ size: fontSizeArr }],
  ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
  [{ color: [] }, { background: [] }],
  [{ list: 'ordered' }, { list: 'bullet' }],
  ['link', 'image'],
  ['clean'],
];

// TODO: There's most likely a bunch of things that don't work in TOPdesk, but we haven't
//  properly mapped it yet.
const topdeskToolbar = standardToolbar;

// TODO: There's most likely a bunch of things that don't work in Puzzel, but we haven't
//  properly mapped it yet.
const puzzelMailToolbar = standardToolbar;

const zendeskSupportToolbar = [
  [],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ size: fontSizeArr }],
  ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
  [{ color: [] }, { background: [] }],
  [{ list: 'ordered' }, { list: 'bullet' }],
  ['link'], // images don't work in Zendesk as they must be handled differently
  ['clean'],
];

export function wysiwygConf(localPlatforms) {
  const modules = {
    clipboard: {
      hooks: {
        uponSanitizeElement(node) {
          if (JSON.stringify(node.textContent) === JSON.stringify('\n')) {
            // eslint-disable-next-line no-param-reassign
            node.textContent = null;
          }
        },
      },
    },
    keyboard: {
      bindings: {
        linebreak: {
          key: 13,
          shiftKey: true,
          handler(range) {
            this.quill.insertEmbed(range.index, 'break', true, 'user');
            this.quill.setSelection(range.index + 1, Quill.sources.SILENT);
          },
        },
      },
    },
    toolbar: null,
  };
  // Select the toolbar based on which system is enabled.
  if (localPlatforms.includes('zendesk-support')) {
    modules.toolbar = zendeskSupportToolbar;
  } else if (localPlatforms.includes('puzzel-ticket')) {
    modules.toolbar = puzzelMailToolbar;
  } else if (localPlatforms.includes('topdesk-incident')) {
    modules.toolbar = topdeskToolbar;
  } else {
    // This will include: localPlatforms.includes('jsonwebhook')
    modules.toolbar = standardToolbar;
  }
  return { modules };
}

export const metricDistributionType = Object.freeze({
  COUNT: 'COUNT',
  COVERAGE: 'COVERAGE',
  RATING: 'RATING',
});

export const timeMetricOptions = Object.freeze([
  { value: 'monthsAccum', text: 'Month of year' },
  { value: 'monthdaysAccum', text: 'Day of month' },
  { value: 'weekdaysAccum', text: 'Day of Week' },
  { value: 'hoursAccum', text: 'Time of day (hours)' },
  { value: 'months', text: 'Month' },
  { value: 'days', text: 'Day' },
  { value: 'hours', text: 'Hour' },
]);

export const automationDetailsType = Object.freeze({
  CONVERSATIONS: 'CONVERSATIONS',
  INTENTS: 'INTENTS',
});

export const PHRASE_REGEX = /\[phrase:[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}\]/g;

export const usertypes = Object.freeze(['Super user', 'Normal user', 'Limited user', 'Observer', 'Transcript user']);

export const gptPersonas = Object.freeze([
  {
    value: null, text: 'Neutral', icon: 'user', description: 'Maintains a neutral tone, focusing on providing unbiased information without expressing emotions or opinions.',
  },
  {
    value: 'formal', text: 'Professional', icon: 'user-tie', description: 'Offers formal, expert guidance and information, maintaining a polished and business-like tone.',
  },
  {
    value: 'friendly', text: 'Friendly', icon: 'face-smile', description: 'Engages in a warm, approachable manner, focusing on creating a comfortable and relaxed interaction.',
  },
  {
    value: 'concise', text: 'Efficient', icon: 'user-clock', description: 'Prioritizes speed and accuracy, delivering concise and direct responses to solve problems quickly.',
  },
  {
    value: 'easy_to_understand', text: 'Simple', icon: 'face-laugh', description: 'Tries to simplify complex information, focusing on clear and easy-to-understand explanations.',
  },
]);
export const emojiOptions = Object.freeze([
  '😄', '😀', '😊', '😉', '😜', '😔', '😒', '😞', '😣', '😥', '😅', '😆', '😷', '😴', '😖', '👍', '👎', '😲', '😐', '😌',
]);

export const apiModes = Object.freeze({
  MOCK: 'mock-api',
  MOCK_EXCEPT_GEN_AI: 'mock-api-except-gen-ai',
  REAL: 'real-api',
});
